/* eslint-disable eqeqeq */
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import $ from "jquery";
import { useEffect } from "react";
import DashBoard from "../tables/Dashboard/DashBoard";
import Booking from "../tables/booking/Booking";
import { AdminProfile } from "./AdminProfile";
import { Expert } from "../tables/expert/Expert";
import Category from "../tables/category/Category";
import Service from "../tables/services/Service";
import { Review } from "../tables/review/Review";
import { User } from "../tables/User/User";
import Setting from "../tables/setting/Setting";
import ExpertProfile from "../tables/expert/ExpertProfile";
import UpcomingBooking from "../tables/booking/UpcomingBooking";
import StaffEarning from "../tables/StaffEarning";
import SalonPayout from "../tables/SalonPayout";
import Attendance from "../tables/Attendance";
import AttendanceTable from "../tables/AttendanceTable";
import UserProfile from "../tables/User/UserProfile";
import DailyBooking from "../tables/booking/DailyBooking";
import ExpertHistory from "../tables/expert/ExpertHistory";
import MonthlyReport from "../../component/tables/booking/MonthlyReport";
import Complain from "../tables/complain/Complain";
import AllPaymentHistory from "../tables/payment/AllPaymentHistory";
import ExpertPaymentHistory from "../tables/payment/ExpertWiseHistory";
import YearlyPayment from "../tables/payment/YearlyPayment";
import Holiday from "../tables/timeSlot/Holiday";
import { Salon } from "../tables/salon/Salon";
import SalonSchedule from "../tables/salon/SalonSchedule";
import ExpertBooking from "../tables/expert/ExpertBooking";
import SalonBooking from "../tables/salon/SalonBooking";
import UserBooking from "../tables/User/UserBooking";
import SalonProfile from "../tables/salon/SalonProfile";
import SalonHistory from "../tables/salon/SalonHistory";
import ParticularExpertEarnings from "../tables/expert/ParticularExpertEarnings";
import ParticularSalonSettlementInfo from "../tables/salon/ParticularSalonSettlementInfo";
import AddSalon from "../tables/salon/AddSalon";
import { ExpertDialogue } from "../tables/expert/ExpertDialogue";
import NotFound from "./NotFound";
import StoreSettlementInfo from "../tables/StoreSettlementInfo";
import BartenderModify from "../tables/User/BartenderModify";
import BartenderProfile from "../tables/User/BartenderProfile";
import { BlackList } from "../tables/User/BlackList";
import WithdrawalSetting from "../tables/setting/WithdrawalSetting";
import { BartenderList } from "../tables/User/BartenderList";
import CouponSetting from "../tables/coupon/CouponSetting";
import { BartenderModifyList } from "../tables/User/BartenderModifyList";
import { NotificationList } from "../tables/Notifications/NotificationList";
import { NoticeList } from "../tables/Notice/NoticeList";
import { FAQList } from "../tables/FAQ/FAQList";
import HashTag from "../tables/hashtag/HashTag";
import { CSList } from "../tables/CS/CSList";
import { AdminList } from "../tables/Admin/AdminList";
import { PermissionList } from "../tables/Permission/PermissionList";
import { LeaderList } from "../tables/Leader/LeaderList";
import ChatSetting from "../tables/ChatSetting/ChatSetting";
import { BartenderNoticeList } from "../tables/BartenderNotice/BartenderNoticeList";
import StockAlarmSetting from "../tables/StockAlarmSetting/StockAlarmSetting";
import { useSelector } from "react-redux";

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "/admin" ||
      location.pathname == "/admin/" ||
      location.pathname == ""
    ) {
      if (admin.permissionCategory?.storeManagement) {
        navigate("/admin/allStore");
        return;
      }
      if (admin.permissionCategory?.reservationManagement) {
        navigate("/admin/store/bookings");
        return;
      }
      if (admin.permissionCategory?.settlementManagement) {
        navigate("/admin/store/settlement");
        return;
      }
      if (admin.permissionCategory?.otherAppManagement) {
        navigate("/admin/push/all");
        return;
      }
      if (admin.permissionCategory?.memberManagement) {
        navigate("/admin/member/all");
        return;
      }
      if (admin.permissionCategory?.announcementManagement) {
        navigate("/admin/notice/all");
        return;
      }
      if (admin.permissionCategory?.adminManagement) {
        navigate("/admin/admin/all");
        return;
      }
    }
  }, [admin, location.pathname, navigate]);

  var webSize = $(window).width();

  return (
    <div className={`mainAdminGrid  ${webSize < 991 && "webAdminGrid"}`}>
      <Sidebar />

      <div className={`mainAdmin`}>
        <Navbar />
        <div className="adminStart">
          <Routes>
            <Route path="/adminDashboard" element={<DashBoard />} />
            <Route path="/bookingTable" element={<Booking />} />
            <Route path="/adminProfile" element={<AdminProfile />} />
            <Route path="/allExperts" element={<Expert />} />
            <Route path="/expert/addExpert" element={<ExpertDialogue />} />
            <Route path="/expert/bookings" element={<ExpertBooking />} />
            <Route path="/categoryTable" element={<Category />} />
            <Route path="/store/menu" element={<Service />} />
            <Route path="/review/setting" element={<Review />} />
            <Route path="/member/all" element={<User />} />
            <Route path="/member/blackList" element={<BlackList />} />
            <Route path="/member/bartenderList" element={<BartenderList />} />
            <Route
              path="/member/bartenderModifyList"
              element={<BartenderModifyList />}
            />
            <Route
              path="/member/bartenderModify"
              element={<BartenderModify />}
            />
            <Route
              path="/member/bartenderProfile"
              element={<BartenderProfile />}
            />
            <Route path="/user/bookings" element={<UserBooking />} />
            <Route path="/introduction/setting" element={<Setting />} />
            <Route path="/withdrawal/setting" element={<WithdrawalSetting />} />
            <Route
              path="/expert/getBartenderProfile"
              element={<ExpertProfile />}
            />
            <Route path="/point/setting" element={<CouponSetting />} />
            <Route path="/staffEarning" element={<StaffEarning />} />
            <Route path="/store/settlement" element={<SalonPayout />} />
            <Route path="/holiday" element={<Holiday />} />
            <Route path="/futureBooking" element={<UpcomingBooking />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/push/all" element={<NotificationList />} />
            <Route path="/notice/all" element={<NoticeList />} />
            <Route
              path="/bartender_notice/all"
              element={<BartenderNoticeList />}
            />
            <Route path="/faq/setting" element={<FAQList />} />
            <Route path="/chatroom/setting" element={<ChatSetting />} />
            <Route path="/hashtag/setting" element={<HashTag />} />
            <Route path="/alarm/setting" element={<StockAlarmSetting />} />
            <Route path="/attendanceTable" element={<AttendanceTable />} />
            <Route path="/user/userProfile" element={<UserProfile />} />
            <Route path="/dailyBookingTable" element={<DailyBooking />} />
            <Route path="/expert/income" element={<ExpertHistory />} />
            <Route
              path="/particularExpert/income"
              element={<ParticularExpertEarnings />}
            />
            <Route path="/monthlyReport" element={<MonthlyReport />} />
            <Route path="/store/complains" element={<Complain />} />
            <Route path="/paymentHistory" element={<AllPaymentHistory />} />
            <Route path="/cs/all" element={<CSList />} />
            <Route path="/admin/all" element={<AdminList />} />
            <Route path="/permission/all" element={<PermissionList />} />
            <Route path="/leader/all" element={<LeaderList />} />
            <Route
              path="/expert/paymentHistory"
              element={<ExpertPaymentHistory />}
            />
            <Route path="/yearlyPayment" element={<YearlyPayment />} />
            <Route path="/allStore" element={<Salon />} />
            <Route
              path="/store/settlementInfo"
              element={<StoreSettlementInfo />}
            />
            <Route path="/store/addStore" element={<AddSalon />} />
            <Route path="/salon/salonProfile" element={<SalonProfile />} />
            <Route path="/store/bookings" element={<SalonBooking />} />
            <Route path="/salon/income" element={<SalonHistory />} />
            <Route path="/store/schedule" element={<SalonSchedule />} />
            <Route
              path="/salon/particularSettlement"
              element={<ParticularSalonSettlementInfo />}
            />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Admin;
