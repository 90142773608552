import React, { useState } from "react";

const ExInput = (props) => {
  const {
    label,
    name,
    id,
    type,
    onChange,
    newClass,
    value,
    errorMessage,
    placeholder,
    disabled,
    readOnly,
    checked,
    onKeyPress,
    accept,
    maxLength,
    minLength,
    className,
    exposeLableNull
  } = props;

  const [types, setTypes] = useState(type);

  const hideShow = () => {
    types === "password" ? setTypes("text") : setTypes("password");
  };

  return (
    <>
      <div
        className={`inputData ${types} ${
          className || ""
        } flex-row justify-content-start text-start`}
      >
        {label && (
          <label
            htmlFor={id}
            className={`${
              (types === "radio" || types === "checkbox") &&
              "ms-2 text-capitalize order-1"
            } text-capitalize`}
          >
            {label}
          </label>
        )}
        {exposeLableNull && (
          <label
            htmlFor={id}
            className={`${
              (types === "radio" || types === "checkbox") &&
              "ms-2 text-capitalize order-1"
            } text-capitalize`}
          >
            &nbsp;
          </label>
        )}
        <input
          type={types}
          className={`${newClass !== undefined ? newClass : ""} ${
            types === "radio" ? "radio-custom" : ""
          }`} // Added custom radio class
          id={id}
          onChange={onChange}
          value={value}
          name={name}
          onWheel={(e) => type === "number" && e.target.blur()}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onKeyPress={onKeyPress}
          checked={checked}
          accept={accept}
          minLength={minLength} // Add minLength
          maxLength={maxLength} // Add maxLength
        />

        {type !== "search" && (
          <p className="errorMessage text-capitalize">
            {errorMessage && errorMessage}
          </p>
        )}

        {type === "password" && (
          <div
            className="passHideShow"
            style={{
              top: errorMessage ? "44%" : "65%"
            }}
            onClick={hideShow}
          >
            {types === "password" ? (
              <i className="fa-solid fa-eye"></i>
            ) : (
              <i className="fa-solid fa-eye-slash"></i>
            )}
          </div>
        )}
        {type === "search" && !value && (
          <div className="searching">
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
        )}
      </div>
    </>
  );
};

export default function GenderGroup(props) {
  const { label, gender, group, onChange } = props;
  return (
    <div className={`inputData flex-row justify-content-start text-start`}>
      {label && <label>{label}</label>}
      <div
        className="d-flex align-items-center"
        style={{ marginTop: "5px", height: "40px" }}
      >
        <div
          className="col-6"
          style={{
            marginBottom: 0
          }}
        >
          <ExInput
            type={`radio`}
            id={`male`}
            label={`남`}
            name={group}
            value={`male`}
            checked={(gender === "male" || gender === "Male") && true}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>

        <div
          className="col-6 ms-2"
          style={{
            marginBottom: 0
          }}
        >
          <ExInput
            type={`radio`}
            id={`female`}
            label={`여`}
            name={group}
            value={`female`}
            checked={(gender === "female" || gender === "Female") && true}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
