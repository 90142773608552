import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { useLocation } from "react-router-dom";

export default function DateRange(props) {
  const { serverSearching } = props;
  const state = useLocation();
  const [date, setDate] = useState(
    state?.state?.row?.start ? new Date(state?.state?.row?.start) : ""
  );
  const [endDate, setEndDate] = useState(
    state?.state?.row?.end ? new Date(state?.state?.row?.end) : ""
  );
  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };
  const handleEndDateChange = (selectedDate) => {
    setEndDate(selectedDate);
  };

  const handleClickSearch = () => {
    serverSearching({ startDate: date, endDate });
  };
  return (
    <div className="co-12 d-flex align-items-center">
      <ReactDatePicker
        selected={date}
        onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
        maxDate={endDate ? endDate : undefined}
      />
      <div className="px-2" style={{ height: "40px", marginTop: "7px" }}>
        -
      </div>
      <ReactDatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        dateFormat="yyyy-MM-dd"
        minDate={date ? date : undefined}
      />
      <button
        className="py-1 bg-button-search"
        style={{
          marginLeft: "10px",
          borderRadius: "8px",
          height: "40px",
          width: "100px",
          minWidth: "100px"
        }}
        onClick={() => handleClickSearch()}
      >
        검색
      </button>
    </div>
  );
}
