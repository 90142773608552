/* eslint-disable no-unused-vars */
import Button from "../../extras/Button";
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { closeDialog, openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import {
  allUsers,
  blockUser,
  unBlockUser
} from "../../../redux/slice/userSlice";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Searching from "../../extras/Searching";
import male from "../../../assets/images/male.png";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import NotificationDialog from "./NotificationDialog";
import { SelectV2 } from "../../extras/Input";
import moment from "moment";
import { confirm } from "../../../util/Alert";
import { apiInstance } from "../../api/axiosApi";
import { genQueryString } from "../../../util/fuction";

const typeSearch = [
  { name: "이름", value: "fullName" },
  { name: "닉네임", value: "nickName" },
  { name: "휴대폰 번호", value: "phoneNumber" }
];

const bartenderStatusSearch = [
  { name: "전체", value: "" },
  { name: "등록", value: "false" },
  { name: "미등록", value: "true" }
];

const suspensionStatusSearch = [
  { name: "전체", value: "" },
  { name: "정지", value: "ACTIVE" },
  { name: "해제", value: "SUSPENDED" }
];

export const SearchingWithSelect = (props) => {
  const [search, setSearch] = useState("");
  const [bartenderStatus, setBartenderStatus] = useState("");
  const [suspensionStatus, setSuspensionStatus] = useState("");
  const [searchType, setSearchType] = useState("");
  const { handleSearch } = props;

  const handleSearchButton = () => {
    handleSearch(search, searchType, bartenderStatus, suspensionStatus);
  };

  return (
    <>
      <div className="">
        <div className="inputDataSearch d-flex gap-4 align-items-end">
          <div className="inputData d-flex flex-column">
            <label>바텐더 등록 여부</label>
            <select
              value={bartenderStatus}
              className="inputTypeSearch"
              style={{
                height: "40px",
                marginTop: "0",
                width: "210px",
                minWidth: "210px"
              }}
              placeholder="선택"
              onChange={(e) => setBartenderStatus(e.target.value)}
            >
              {bartenderStatusSearch.map((item) => (
                <option value={item.value} key={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="inputData d-flex flex-column">
            <label>회원 정지 여부</label>
            <select
              value={suspensionStatus}
              className="inputTypeSearch"
              style={{
                height: "40px",
                marginTop: "0",
                width: "210px",
                minWidth: "210px"
              }}
              placeholder="선택"
              onChange={(e) => setSuspensionStatus(e.target.value)}
            >
              {suspensionStatusSearch.map((item) => (
                <option value={item.value} key={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="inputDataSearch d-flex gap-4 align-items-end">
          <div className="inputData">
            <label>바텐더 검색</label>
            <select
              value={searchType}
              className="inputTypeSearch"
              style={{
                height: "40px",
                marginTop: "0",
                width: "110px",
                minWidth: "110px"
              }}
              placeholder="선택"
              onChange={(e) => setSearchType(e.target.value)}
            >
              {typeSearch.map((item) => (
                <option value={item.value} key={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <input
            type="search"
            id="search"
            // placeholder="Searching for..."
            className="bg-none m0-top w-100"
            style={{ fontWeight: "500", height: "40px" }}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button
            className="py-1 bg-button-search"
            style={{
              borderRadius: "8px",
              height: "40px",
              width: "100px",
              minWidth: "100px"
            }}
            onClick={() => handleSearchButton()}
          >
            검색
          </button>
        </div>
      </div>
    </>
  );
};

export const BlackList = () => {
  const dispatch = useDispatch();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { user, total } = useSelector((state) => state?.user);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState({
    bartenderStatus: "",
    suspensionStatus: "",
    type: "",
    text: ""
  });
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      status: search.suspensionStatus,
      isBartender: search.bartenderStatus,
      searchText: search.text,
      searchType: search.type
    };
    dispatch(allUsers(payload));
  }, [
    dispatch,
    page,
    rowsPerPage,
    search.bartenderStatus,
    search.suspensionStatus,
    search.text,
    search.type
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setData(user);
  }, [user]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const handleStop = async (id) => {
    try {
      const data = await confirm({
        title: "홍길동 회원의 활동을 정지 하시겠습니까?",
        text: "확인 버튼 클릭 시 해당 회원의 활동이 정지됩니다.",
        confirm: "확인",
        cancle: "취소",
        iconHtml: ""
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        const res = await dispatch(blockUser(id));
        if (res?.payload?.data?.data) {
          fetchData();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnStop = async (id) => {
    try {
      const data = await confirm({
        title: "홍길동 회원의 정지를 해제 하시겠습니까?",
        text: "확인 버튼 클릭 시 해당 회원의 활동이 정지됩니다.",
        confirm: "확인",
        cancle: "취소",
        iconHtml: ""
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        const res = await dispatch(unBlockUser(id));
        if (res?.payload?.data?.data) {
          fetchData();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const userTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "이름",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.fullname}</span>
      )
    },
    {
      Header: "닉네임",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.nickname}</span>
      )
    },
    {
      Header: "바텐더 등록 여부",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.isBartender ? "등록" : "미등록"}
        </span>
      )
    },
    {
      Header: "휴대폰 번호",
      Cell: ({ row }) => <span>{row?.phoneNumber}</span>
    },
    {
      Header: "보유 포인트",
      Cell: ({ row }) => <span>{row?.pointBalance || 0}p</span>
    },
    {
      Header: "예약 취소 일자",
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          {(row?.cancelledDates || []).map((item) => (
            <span>{item && moment(item).format("YYYY-MM-DD")}</span>
          ))}

          {/* <span>
            {row?.bookingDate && moment(row.bookingDate).format("YYYY-MM-DD")}
          </span>
          <span>
            {row?.bookingDate && moment(row.bookingDate).format("YYYY-MM-DD")}
          </span> */}
        </div>
      )
    },
    {
      Header: "프로필 상세",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleInfo(row)}
        >
          프로필 보기
        </button>
      )
    },
    {
      Header: "회원 정지",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => {
            if (row?.status === "SUSPENDED") {
              handleUnStop(row?.id);
            } else {
              handleStop(row?.id);
            }
          }}
        >
          {row?.status === "SUSPENDED" ? "해제" : "정지"}
        </button>
      )
    }
  ];

  const handleSearch = (text, type, bartenderStatus, suspensionStatus) => {
    if (
      text !== search.text ||
      type !== search.type ||
      bartenderStatus !== search.bartenderStatus ||
      suspensionStatus !== search.suspensionStatus
    ) {
      setPage(0);
    }
    setSearch({
      bartenderStatus,
      suspensionStatus,
      type,
      text
    });
  };

  const handleInfo = (row) => {
    navigate("/admin/user/userProfile", {
      state: {
        row
      }
    });
  };

  const handleProfile = (row, type) => {
    dispatch(openDialog({ type: type, data: row }));
    localStorage.setItem(
      "dialog",
      JSON.stringify({ dialogue: true, type: type, data: row })
    );
  };
  const handleNotify = (id) => {
    dispatch(openDialog({ type: "notification", data: { id, type: "user" } }));
  };

  const handleStatus = (id) => {
    dispatch(blockUser(id));
  };

  const handleDownloadExcel = async () => {
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
      status: search.suspensionStatus,
      isBartender: search.bartenderStatus,
      searchText: search.text,
      searchType: search.type
    };

    const res = await apiInstance.get(genQueryString("admin/user", payload));
    console.log("res", res);
    const flattenedData = (res?.data || []).map((item, index) => ({
      순서: index + 1,
      이름: item?.fullname,
      닉네임: item?.nickname,
      "바텐더 등록 여부": item?.isBartender ? "등록" : "미등록",
      전화번호: item?.phoneNumber,
      "보유 포인트": (item?.pointBalance || 0) + "p"
    }));

    const headers = [
      "순서",
      "이름",
      "닉네임",
      "바텐더 등록 여부",
      "전화번호",
      "보유 포인트"
    ];
    const ws = XLSX.utils.json_to_sheet(flattenedData);

    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let col = 0; col < headers.length; col++) {
      const cellAddress = { r: range.s.r, c: col };
      const cell = { v: headers[col], t: "s" };
      ws[XLSX.utils.encode_cell(cellAddress)] = cell;
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "블랙 리스트");
    XLSX.writeFile(wb, "블랙 리스트.xlsx");
  };

  return (
    <div className="mainCategory">
      {dialogue && dialogueType === "notification" && (
        <div className="userTable">
          <NotificationDialog />
        </div>
      )}
      <div
        className="userTable"
        style={{
          display: `${dialogueType === "userHistory" ? "none" : "block"}`
        }}
      >
        <Title name="블랙 리스트" />
        <div className="betBox mb-4">
          <div className="col-md-8 col-lg-7 inputData mb-0">
            <SearchingWithSelect handleSearch={handleSearch} />
          </div>
          <div className="ms-auto mt-auto">
            <Button
              className="bg-button-excel text-black p5-y"
              onClick={handleDownloadExcel}
              text={`다운로드`}
            />
          </div>
        </div>
        <div>
          <Table
            data={data}
            mapData={userTable}
            PerPage={rowsPerPage}
            Page={page}
            type={"server"}
          />
          <Pagination
            type={"client"}
            serverPage={page}
            setServerPage={setPage}
            serverPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            totalData={total}
          />
        </div>
      </div>
    </div>
  );
};
